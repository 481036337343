body{
  background: rgb(25,37,58);
  background: linear-gradient(90deg, rgba(25,37,58,1) 0%, rgba(24,36,58,1) 25%, rgba(18,24,39,1) 50%, rgba(25,28,33,1) 75%, rgba(19,18,18,1) 90%, rgba(12,8,0,1) 100%);
  color: white;
  font-family: Roboto, Arial, sans-serif;
}
.why_us_container_m17{
  padding: 200px 0px;
}
// text animation in home
.top_home_container_m17{
  margin-top: 70px;
}
@keyframes showTopText {
  0% { transform: translate3d(0, 100%, 0); }
  40%, 60% { transform: translate3d(0, 50%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes showBottomText {
  0% { transform: translate3d(0, -100%, 0); }
  100% { transform: translate3d(0, 0, 0); }
}
@keyframes border0to100 {
  to{
    width: 90%;
  }
}
.top_home_text_animation_m17 {
  position: relative;
  height: 250px;
}
.top_home_text_animation_m17 > div {
  height: 50%;
  overflow: hidden;
  position: absolute;
  width: 0%;
  animation: border0to100 1s linear  0.5s 1 forwards;
}

.top_home_text_animation_m17 > div div {
  font-size: 12vmin;
  padding: 2vmin 0;
  position: absolute;
}
.top_home_text_animation_m17 > div div span {
  display: block;
}
.top_home_text_animation_m17 > div.text-top {
  border-bottom: 1vmin solid white;
  top: 0;
}
.top_home_text_animation_m17 > div.text-top div {
  animation: showTopText 1s;
  animation-delay: 1.6s;
  animation-fill-mode: forwards;
  bottom: 0;
  transform: translate(0, 100%);
  
}
.top_home_text_animation_m17 > div.text-top div span:first-child {
  color: #767676;
}
.top_home_text_animation_m17 > div.text-bottom {
  bottom: 0;
}
.top_home_text_animation_m17 > div.text-bottom div {
  animation: showBottomText 0.5s;
  animation-delay: 2.6s;
  animation-fill-mode: forwards;
  top: 0;
  transform: translate(0, -100%);
}

.watch-now-btn:hover{
  transition-duration: 1s;
  i{
    background-color: #dc3545;
    &::before{
      transition-duration: 1s;
      color: white;
    }
  }
}
.our_partner_m17{
  background-color: white;
  margin: 20px;
}

.page_action_error{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.page_action_error svg{
  cursor: pointer;
  animation: rotate 0.8s linear infinite;
  animation-play-state: paused;
  margin-top: 12px;
}
.playAnimation{
  animation-play-state: running !important;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.portfolio-item-img{
  width: 360px;
  height: 300px;
  img{
    width: 100%;
    height: 100%;
  }
}

.custom_test_m17{
  opacity: 0.5;
  transition-duration: 1s;
  &:hover{
    opacity: 1;
    color: white !important;
  }
}

html *,
body * {
  cursor:url("./Images/logo/cursor.png"), auto;
}

.game-service-wrapper{
  transition-duration: 0.6s;
  &:hover{
    background-color: #0f0c08;
    .game-service-icon{
      background-color: #131b2d;
    }
  }
}

.portfolio-item-img{
  width: 100%;
  height: 320px;
}

.custom_no_image_m17{
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background-color: #131b2d;
  color: white !important;
  font-weight: bold;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-nav-control{
  position: relative;
  .swiper-button-next , .swiper-button-prev{
    position: absolute;
    top: -95px;
    font-weight: bold;
    color: white;
    width: 35px;
    height: 35px;
  }
  .swiper-button-next{
    right: -25px;
    background-color: #162237;
    border-color: #162237;
    border-color: #162237;
    &:hover{
      border-color: #162237;
      background-color: #162237;
    }
  }
  .swiper-button-prev{
    left: -25px;
    background-color: #141313;
    border-color: #141313;
    &:hover{
      border-color: #141313;
      background-color: #141313;
    }
  }
}
.custom_card_rate_m17{
  min-height: 190px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
}

.footer{
  background-color: #0e1320;
  padding: 20px 0px;
}

@media (max-width:992px) {
  .why_choose_us_m17{
    padding-top: 40px;
    text-align: center;
  }
  .cards_row2_m17{
    margin-top: 200px !important;
  }
}

@media (max-width:768px) {
  .top_home_text_animation_m17{
    height: 200px;
    .text-top{
      border-bottom: 3px solid white !important;
      span{
        font-size: 1.6rem !important;
      }
    }
    .text-bottom{
      div{
        font-size: 1.6rem !important;
      }
    }
  }
  .action-btn a{
    margin: -10px 0px 0px !important;
  }
  .cards_row2_m17{
    margin-top: 0px !important;
  }
}

@media (max-width:580px) {
  .swiper-nav-control{
    display: none;
  }
  .custom_card_rate_m17{
    min-height: 220px;
  }
  .custom_no_image_m17{
    width: 35px;
    height: 35px;
    font-size: 16px;
  }
  .author-info{
    h6{
      font-size: 0.9rem;
    }
  }
  .review-rate{
    svg{
      width: 20px;
      height: 20px;
    }
  }
}
@media (max-width:470px) {
  .top_home_text_animation_m17{
    height: 150px;
    .text-top{
      border-bottom: 2px solid white !important;
      span{
        font-size: 1.3rem !important;
      }
    }
    .text-bottom{
      div{
        font-size: 1.3rem !important;
      }
    }
  }
  .action-btn a{
    font-size: 1rem !important;
  }
  .watch-now-btn i{
    width: 25px !important;
    height: 25px !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .portfolio-item-img{
    height: 300px;
  }
  .custom_card_rate_m17{
    min-height: 200px;
    small{
      font-size: 0.7rem;
    }
  }
  .custom_no_image_m17{
    width: 30px;
    height: 30px;
    font-size: 14px;
  }
  .author-info{
    h6{
      font-size: 0.8rem;
      margin-bottom: -5px !important;
    }
  }
  .review-rate{
    svg{
      width: 18px;
      height: 18px;
    }
  }
}

@media (max-width:365px) {
  .top_home_text_animation_m17{
    height: 100px;
    .text-top{
      span{
        font-size: 1rem !important;
      }
    }
    .text-bottom{
      div{
        font-size: 1rem !important;
      }
    }
  }
  .action-btn{
    margin-top: -20px !important;
    a{
      font-size: 0.8rem !important;
    }
  }
  .portfolio-item-img{
    height: 250px;
  }
}

@media (max-width:319px) {
  .cards_row2_m17{
    margin-top: 200px !important;
  }
}